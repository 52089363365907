<template>
  <div class="ada-notification-table-container">
    <CCard class="ada-notification-table-card">
      <CDataTable :items="items" :fields="fields" fixed hover striped bordered>
        <template ##="{item, index}">
          <td>{{index + 1 + (activePage-1)*limit}}</td>
        </template>

        <template #block="{item}">
          <td>{{item.block}}</td>
        </template>

        <template #size="{item}">
          <td>{{item.size | currency('') }}</td>
        </template>

        <template #createdAt="{item}">
          <td>{{(item.createdAt) | moment(dateFormat)}}</td>
        </template>
      </CDataTable>
    </CCard>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import { Constants } from '@/constants';

export default {
  name: 'AdaNotificationHistoriesTable',
  components: {},
  data() {
    return {
      dateFormat: Constants.dateFormat,
      fields: [
        {
          key: '#',
          label: this.$t('ADA_NOTIFICATION_LIST_TABLE_COMPONENT_NUMBER_COL'),
        },
        {
          key: 'epoch',
          label: this.$t('ADA_NOTIFICATION_LIST_TABLE_COMPONENT_EPOCH_COL'),
        },
        {
          key: 'block',
          label: this.$t('ADA_NOTIFICATION_LIST_TABLE_COMPONENT_BLOCK_NAME_COL'),
        },
        {
          key: 'name',
          label: this.$t('ADA_NOTIFICATION_LIST_TABLE_COMPONENT_VALIDATOR_NAME_COL'),
        },
        {
          key: 'size',
          label: this.$t('ADA_NOTIFICATION_LIST_TABLE_COMPONENT_STAKED_TOTAL_COL'),
        },
        {
          key: 'createdAt',
          label: this.$t('ADA_NOTIFICATION_LIST_TABLE_COMPONENT_CREATED_AT_COL'),
          _style: 'min-width: 150px; text-align: left;',
        },
      ],
    };
  },
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    activePage: {
      type: Number,
      default: 0,
    },
    limit: {
      type: Number,
      default: 0,
    },
  },
  methods: {},
};
</script>

<style lang="scss">
.ada-notification-table-container {
  .ada-notification-table-card {
    .table {
      margin-bottom: 0;
    }
  }
}
</style>
