var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"update-notify-config-container wrapper"},[_c('CRow',[_c('CCol',{attrs:{"lg":"12","md":"12"}},[_c('CCard',[_c('CCardHeader',[_c('h1',[_vm._v(_vm._s(_vm.$t('ADA_NOTIFY_CONFIG_PAGE_TITLE')))])]),(_vm.isLoading)?_c('CCardBody',[_c('vcl-list')],1):_vm._e(),(!_vm.isLoading)?_c('CCardBody',[_c('CRow',[_c('CCol',{attrs:{"md":"9","ld":"9"}},[_c('ValidationObserver',{ref:"form",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('CForm',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.onSubmit)}}},[_c('ValidationProvider',{attrs:{"name":"ADA_NOTIFY_CONFIG_PAGE_IS_ENABLED_FIELD_NAME","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return _c('CRow',{staticClass:"form-group"},[_c('CCol',{staticClass:"label-group",attrs:{"lg":"3","md":"3"}},[_c('label',{},[_vm._v(_vm._s(_vm.$t('ADA_NOTIFY_CONFIG_PAGE_IS_ENABLED_LABEL'))+":")])]),_c('CCol',{attrs:{"lg":"9","md":"9"}},[_c('div',{staticClass:"btn-switch-wrapper"},[_c('label',{staticClass:"btn-switch"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.config.is_enabled),expression:"config.is_enabled"}],staticClass:"checkbox",attrs:{"type":"checkbox"},domProps:{"checked":Array.isArray(_vm.config.is_enabled)?_vm._i(_vm.config.is_enabled,null)>-1:(_vm.config.is_enabled)},on:{"change":function($event){var $$a=_vm.config.is_enabled,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.config, "is_enabled", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.config, "is_enabled", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.config, "is_enabled", $$c)}}}}),_c('span',{staticClass:"check-silder"})])]),_c('span',{staticClass:"error-msg-wrap"},[_c('span',{staticClass:"error-msg"},[_vm._v(_vm._s(errors[0]))])])])],1)}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"ADA_NOTIFY_CONFIG_PAGE_STAKED_TOTAL_FIELD_NAME","rules":"required|min_value:0"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return _c('CRow',{staticClass:"form-group"},[_c('CCol',{staticClass:"label-group",attrs:{"lg":"3","md":"3"}},[_c('label',[_vm._v(_vm._s(_vm.$t('ADA_NOTIFY_CONFIG_PAGE_STAKED_TOTAL_LABEL'))+":")])]),_c('CCol',{attrs:{"lg":"9","md":"9"}},[_c('div',{staticClass:"input-group"},[_c('currency-input',{staticClass:"input-field form-control",attrs:{"precision":5},model:{value:(_vm.config.size),callback:function ($$v) {_vm.$set(_vm.config, "size", $$v)},expression:"config.size"}}),_c('div',{staticClass:"input-group-prepend"},[_c('div',{staticClass:"input-group-text"},[_vm._v("ADA")])])],1),_c('div',{staticClass:"error-msg-wrap"},[_c('span',{staticClass:"error-msg"},[_vm._v(_vm._s(errors[0]))])])])],1)}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"ADA_NOTIFY_CONFIG_PAGE_NOTIFY_EMAILS_FIELD_NAME","rules":"required|max:1000"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return _c('CRow',{staticClass:"form-group"},[_c('CCol',{staticClass:"label-group",attrs:{"lg":"3","md":"3"}},[_c('label',[_vm._v(_vm._s(_vm.$t('ADA_NOTIFY_CONFIG_PAGE_NOTIFY_EMAILS_LABEL'))+":")])]),_c('CCol',{attrs:{"lg":"9","md":"9"}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.config.emails),expression:"config.emails"}],staticClass:"form-control",domProps:{"value":(_vm.config.emails)},on:{"change":_vm.onChangeEmails,"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.config, "emails", $event.target.value)}}}),_c('div',{staticClass:"error-msg-wrap"},[_c('span',{staticClass:"error-msg"},[_vm._v(_vm._s(errors[0]))])]),(_vm.errorMessage)?_c('div',{staticClass:"error-msg-wrap"},[_c('span',{staticClass:"error-msg"},[_vm._v(_vm._s(_vm.errorMessage))])]):_vm._e()])],1)}}],null,true)}),_c('CRow',{staticClass:"form-actions mt-3"},[_c('CCol',{attrs:{"lg":"12","md":"12"}},[(_vm.isAllowedUpdating)?_c('CButton',{staticClass:"btn btn-primary",attrs:{"color":"primary"},on:{"click":_vm.onSubmit}},[_vm._v(_vm._s(_vm.$t('ADA_NOTIFY_CONFIG_PAGE_BUTTON_SAVE')))]):_vm._e()],1)],1)],1)]}}],null,false,1901816359)})],1)],1),_c('div',{staticClass:"ada-notification-histories-container"},[_c('h3',[_vm._v(_vm._s(_vm.$t("ADA_NOTIFY_CONFIG_PAGE_NOTIFICATION_HISTORY_TITLE")))]),_c('CRow',[_c('CCol',{attrs:{"md":"12"}},[_c('AdaNotificationHistoriesTable',{attrs:{"limit":_vm.limit,"activePage":_vm.activePage,"items":_vm.items,"total":_vm.total}})],1),_c('CCol',{attrs:{"md":"12"}},[_c('StakingPagination',{attrs:{"limit":_vm.limit,"total":_vm.total,"activePage":_vm.activePage},on:{"changePage":_vm.onChangePage,"changePageSize":_vm.onChangePageSize}})],1)],1)],1)],1):_vm._e()],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }