var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"ada-notification-table-container"},[_c('CCard',{staticClass:"ada-notification-table-card"},[_c('CDataTable',{attrs:{"items":_vm.items,"fields":_vm.fields,"fixed":"","hover":"","striped":"","bordered":""},scopedSlots:_vm._u([{key:"#",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_c('td',[_vm._v(_vm._s(index + 1 + (_vm.activePage-1)*_vm.limit))])]}},{key:"block",fn:function(ref){
var item = ref.item;
return [_c('td',[_vm._v(_vm._s(item.block))])]}},{key:"size",fn:function(ref){
var item = ref.item;
return [_c('td',[_vm._v(_vm._s(_vm._f("currency")(item.size,'')))])]}},{key:"createdAt",fn:function(ref){
var item = ref.item;
return [_c('td',[_vm._v(_vm._s(_vm._f("moment")((item.createdAt),_vm.dateFormat)))])]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }