<template>
  <div class="update-notify-config-container wrapper">
    <CRow>
      <CCol lg="12" md="12">
        <CCard>
          <CCardHeader>
            <h1>{{$t('ADA_NOTIFY_CONFIG_PAGE_TITLE')}}</h1>
          </CCardHeader>
          <CCardBody v-if="isLoading">
            <vcl-list></vcl-list>
          </CCardBody>

          <CCardBody v-if="!isLoading">
            <CRow>
              <CCol md="9" ld="9">
                <ValidationObserver ref="form" v-slot="{ handleSubmit }">
                  <CForm @submit.prevent="handleSubmit(onSubmit)">
                    <ValidationProvider name="ADA_NOTIFY_CONFIG_PAGE_IS_ENABLED_FIELD_NAME" rules>
                      <CRow class="form-group" slot-scope="{ errors }">
                        <CCol lg="3" md="3" class="label-group">
                          <label class>{{$t('ADA_NOTIFY_CONFIG_PAGE_IS_ENABLED_LABEL')}}:</label>
                        </CCol>
                        <CCol lg="9" md="9">
                          <div class="btn-switch-wrapper">
                            <label class="btn-switch">
                              <input type="checkbox" class="checkbox" v-model="config.is_enabled" />
                              <span class="check-silder"></span>
                            </label>
                          </div>
                          <span class="error-msg-wrap">
                            <span class="error-msg">{{errors[0]}}</span>
                          </span>
                        </CCol>
                      </CRow>
                    </ValidationProvider>

                    <ValidationProvider
                      name="ADA_NOTIFY_CONFIG_PAGE_STAKED_TOTAL_FIELD_NAME"
                      rules="required|min_value:0"
                    >
                      <CRow class="form-group" slot-scope="{ errors }">
                        <CCol lg="3" md="3" class="label-group">
                          <label>{{$t('ADA_NOTIFY_CONFIG_PAGE_STAKED_TOTAL_LABEL')}}:</label>
                        </CCol>
                        <CCol lg="9" md="9">
                          <div class="input-group">
                            <currency-input
                              class="input-field form-control"
                              v-model="config.size"
                              :precision="5"
                            />
                            <div class="input-group-prepend">
                              <div class="input-group-text">ADA</div>
                            </div>
                          </div>

                          <div class="error-msg-wrap">
                            <span class="error-msg">{{errors[0]}}</span>
                          </div>
                        </CCol>
                      </CRow>
                    </ValidationProvider>

                    <ValidationProvider
                      name="ADA_NOTIFY_CONFIG_PAGE_NOTIFY_EMAILS_FIELD_NAME"
                      rules="required|max:1000"
                    >
                      <CRow class="form-group" slot-scope="{ errors }">
                        <CCol lg="3" md="3" class="label-group">
                          <label>{{$t('ADA_NOTIFY_CONFIG_PAGE_NOTIFY_EMAILS_LABEL')}}:</label>
                        </CCol>
                        <CCol lg="9" md="9">
                          <input
                            class="form-control"
                            v-model="config.emails"
                            @change="onChangeEmails"
                          />
                          <div class="error-msg-wrap">
                            <span class="error-msg">{{errors[0]}}</span>
                          </div>
                          <div class="error-msg-wrap" v-if="errorMessage">
                            <span class="error-msg">{{errorMessage}}</span>
                          </div>
                        </CCol>
                      </CRow>
                    </ValidationProvider>

                    <CRow class="form-actions mt-3">
                      <CCol lg="12" md="12">
                        <CButton
                          v-if="isAllowedUpdating"
                          color="primary"
                          class="btn btn-primary"
                          @click="onSubmit"
                        >{{$t('ADA_NOTIFY_CONFIG_PAGE_BUTTON_SAVE')}}</CButton>
                      </CCol>
                    </CRow>
                  </CForm>
                </ValidationObserver>
              </CCol>
            </CRow>

            <div class="ada-notification-histories-container">
              <h3>{{$t("ADA_NOTIFY_CONFIG_PAGE_NOTIFICATION_HISTORY_TITLE")}}</h3>
              <CRow>
                <CCol md="12">
                  <AdaNotificationHistoriesTable
                    :limit="limit"
                    :activePage="activePage"
                    :items="items"
                    :total="total"
                  />
                </CCol>
                <CCol md="12">
                  <StakingPagination
                    :limit="limit"
                    :total="total"
                    :activePage="activePage"
                    @changePage="onChangePage"
                    @changePageSize="onChangePageSize"
                  />
                </CCol>
              </CRow>
            </div>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  </div>
</template>
<script>
import _ from 'lodash';
import { mapState, mapActions, mapGetters } from 'vuex';
import { VclList } from 'vue-content-loading';
import moment from 'moment';
import responseHelper from '@/utils/response-helper';
import { MembershipOrderStatus } from '@/enums';
import { endpoints, env } from '@/constants';
import { VeeValidate } from 'vee-validate';
import { email } from 'vee-validate';
import * as rules from 'vee-validate/dist/rules';
import AdaNotificationHistoriesTable from '@/components/AdaNotifyConfig/AdaNotificationHistoriesTable';
import StakingPagination from '@/components/StakingPagination';

export default {
  name: 'ADANotifyConfig',
  components: {
    VclList,
    AdaNotificationHistoriesTable,
    StakingPagination,
  },
  data() {
    return {
      config: {
        is_enabled: false,
        size: null,
        emails: null,
      },
      isLoading: true,
      isProcessing: false,
      isAllowedUpdating: false,
      errorMessage: null,
      items: [],
      total: 0,
      limit: env.app.pageSize,
      activePage: 1,
    };
  },
  computed: {
    ...mapGetters(['userPermissions']),
    offset() {
      return (this.activePage - 1) * this.limit;
    },
    pages() {
      return Math.ceil(this.total / this.limit);
    },
  },
  async mounted() {
    this.checkPermissions();
    await Promise.all([this.getAdaPoolNotifyConfig(), this.getHistories()]);
    this.isLoading = false;
  },
  methods: {
    checkPermissions() {
      if (this.userPermissions.length > 0) {
        this.userPermissions.forEach(item => {
          if (item.name === 'UPDATE_ADA_POOL_NOTIFY_CONFIG') {
            this.isAllowedUpdating = true;
          }
        });
      }
    },
    async getAdaPoolNotifyConfig() {
      try {
        const result = await this.$http.get(endpoints.getAdaPoolNotify, {});
        this.config = result.data;
      } catch (err) {
        this.$notify({
          group: 'notifications',
          type: 'error',
          title: this.$t('ADA_NOTIFY_CONFIG_PAGE_NOTIFY_ERROR_TITLE'),
          text: responseHelper.getErrorMessage(err),
        });
      }
    },
    onChangeEmails() {
      const emailAddresses = [];
      const emails = _.trim(this.config.emails || '')
        .split(',')
        .map(x => _.trim(x))
        .filter(x => !!x);

      const hasValidEmails = _.every(emails, email => {
        if (!rules.email.validate(email)) {
          emailAddresses.push(email);
          return false;
        }

        return true;
      });

      if (!hasValidEmails) {
        this.errorMessage = responseHelper.getMessageByCode('ADA_NOTIFY_CONFIG_PAGE_INVALID_EMAILS', {
          emailAddresses: emailAddresses.join(', '),
        });
        return;
      } else {
        this.errorMessage = null;
      }
    },
    async onSubmit() {
      this.$refs.form.validate().then(isValid => {
        if (!isValid || this.errorMessage) {
          return;
        }

        this.update();
      });
    },
    async update() {
      if (this.isProcessing) {
        return;
      }

      this.isProcessing = true;
      try {
        const data = {
          is_enabled: this.config.is_enabled,
          size: this.config.size,
          emails: this.config.emails,
        };
        const result = await this.$http.put(endpoints.updateAdaPoolNotify, data);

        this.$notify({
          group: 'notifications',
          type: 'success',
          title: this.$t('ADA_NOTIFY_CONFIG_PAGE_NOTIFY_SUCCESS_TITLE'),
          text: this.$t('ADA_NOTIFY_CONFIG_PAGE_NOTIFY_SUCCESS_MESSAGE'),
        });
        this.isProcessing = false;
      } catch (err) {
        this.isProcessing = false;

        this.$notify({
          group: 'notifications',
          type: 'error',
          title: this.$t('ADA_NOTIFY_CONFIG_PAGE_NOTIFY_ERROR_TITLE'),
          text: responseHelper.getErrorMessage(err),
        });
      }
    },
    async getHistories(data) {
      data = data || {};

      try {
        const params = {
          limit: this.limit,
          offset: this.offset,
        };
        const result = await this.$http.get(endpoints.getAdaPoolNotificationHistories, { params });

        this.items = result.data.items || [];
        this.total = result.data.total;
      } catch (err) {
        this.$notify({
          group: 'notifications',
          type: 'error',
          title: this.$t('ORDER_LIST_PAGE_TITLE_NOTIFY_ERROR'),
          text: responseHelper.getErrorMessage(err),
        });
      }
    },
    async onChangePage(page) {
      this.activePage = page;

      this.getHistories();
    },
    async onChangePageSize(value) {
      this.limit = value;
      this.activePage = 1;

      this.getHistories();
    },
  },
};
</script>
<style lang="scss">
.update-notify-config-container {
  &.wrapper {
    padding: 0 24px;
  }

  .card {
    > .card-header {
      border-bottom: none;
      padding-bottom: 0px;

      h1 {
        color: #657187;
        font-size: 18px;
        font-weight: 600;
        line-height: 34px;
        margin-bottom: 15px;
      }
      p {
        font-size: 14px;
        font-weight: 300;
        line-height: 20px;
        color: #3e4b65;
        padding-top: 15px;
        margin-bottom: 15px;
      }
    }
    h2 {
      color: #657187;
      font-size: 16px;
      font-weight: 600;
      line-height: 34px;
      margin-bottom: 0px;
    }

    .form-group {
      margin-bottom: 0.5rem;
    }
    .label-group {
      text-align: right;
      label {
        font-size: 14px;
        font-weight: 300;
        color: #3e4b65;
      }
    }

    .input-group {
      input {
        flex: 0 0 50%;
        width: 50%;
        font-size: 16px;
        border-radius: 4px !important;
        color: #3a4a7f;
      }
      .input-group-prepend {
        .input-group-text {
          width: 65px;
          background-color: unset;
          border: none;
        }
      }
    }

    .form-actions {
      text-align: right;
      .btn {
        border: none;
        border-radius: 4px;
        width: 145px;
        height: 36px;
        padding: 0 30px;
        color: #fff;
        margin: 30px 0 15px 15px;

        &.btn-primary {
          background: #3b4b7e;
        }
      }
    }
  }

  .ada-notification-histories-container {
    h3 {
      color: #657187;
      font-size: 16px;
      font-weight: 600;
      line-height: 34px;
      margin-bottom: 0px;
    }
  }
}
</style>
